import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

import './product_info.scss'

const ProductInfoPage = () => {
  return (
    <Layout hero={<HeroImage title="Product Information" />} mainContainer={{fluid: "xxl"}}>
      <div className="content-row-container">
        <Row className="d-flex justify-content-center content-row">
          <Col sm={10}>
            <Row>
              <Col sm={12}>
                <p>
                  Orrcon Steel is a leading Australian distributor and manufacturer of steel, tube and pipe.
                  Our extensive product range covers RHS, SHS & CHS structural tubular steel, hot rolled structural steel and a variety of fencing, 
                  roofing and building accessories.
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/tube-pipe" className="mb-2">
                    <h2 className="title">Tube & Pipe</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={6}>
                    <div className="linked-list info">
                      <Link to="#" className="mb-2">
                        Structural
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/circular-hollow-section" className="mb-2">
                            Circular Hollow Section
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/rectangular-hollow-section" className="mb-2">
                            Rectangular Hollow Section
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/sprinkler-and-hydrant-pipe" className="mb-2">
                            Sprinkler And Hydrant Pipe
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/structural/square-hollow-section" className="mb-2">
                            Square Hollow Section
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="linked-list info">
                      <Link to="#" className="mb-2">
                        Precision
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/circular-pipe" className="mb-2">
                            Circular Pipe
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/oval-tube" className="mb-2">
                            Oval Tube Steel
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/rectangular-tube" className="mb-2">
                            Rectangular Tube
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/special-shapes" className="mb-2">
                            Special Shapes
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/tube-pipe/precision/square-tube" className="mb-2">
                            Square Tube
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={6}>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/broadspec-pipe" className="mb-2">
                        Broadspec Pipe
                      </Link>
                      <p>BROADSPEC® offers a range of structural steel tubulars and pressure pipe products that comply with multiple industry standards.</p>
                    </div>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/yard-rail" className="mb-2">
                        Yard Rail
                      </Link>
                      <p>Orrcon Steel's Australian Made yard rail is a safe and economical steel solution for managing and protecting valuable livestock.</p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/hot-dipped-galvanised" className="mb-2">
                        Hot Dipped Galvanised
                      </Link>
                      <p>Hot Dip Galvanised finished products are perfect for jobs that require longer lasting protection against all weather and all conditions.</p>
                    </div>
                    <div className="content info">
                      <Link to="/product-info/tube-pipe/silo-section" className="mb-2">
                        Silo Section
                      </Link>
                      <p>A unique profile used to replace RHS in the Silo base ring. Available in our ALLGAL® finish.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                    <h2 className="title">HOT ROLLED STRUCTURAL STEEL</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="linked-list info">
                      <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                        Structural Angle
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/structural-angle/equal-angle" className="mb-2">
                            Equal Angle
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/structural-angle/unequel-angle" className="mb-2">
                            Unequal Angle
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="linked-list info">
                      <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                        Merchant Bar
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/merchant-bar/flats" className="mb-2">
                            Flats
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/merchant-bar/rounds" className="mb-2">
                            Rounds
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/merchant-bar/square" className="mb-2">
                            Square
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="linked-list info">
                      <Link to="/product-info/hot-rolled-structural-steel" className="mb-2">
                        Plate & Sheet
                      </Link>
                      <ul>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/plate-sheet/steel-floor-checker" className="mb-2">
                            Steel Floor (Checker)
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/plate-sheet/steel-plate" className="mb-2">
                            Steel Plate
                          </Link>
                        </li>
                        <li>
                          <Link to="/product-info/hot-rolled-structural-steel/plate-sheet/steel-sheet" className="mb-2">
                            Steel Sheet
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/hot-rolled-structural-steel/beam" className="mb-2">
                        Beam
                      </Link>
                      <p>Universal Beams Available.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/hot-rolled-structural-steel/channel" className="mb-2">
                        Channel
                      </Link>
                      <p>Manufactured to Australian Standards.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/hot-rolled-structural-steel/column" className="mb-2">
                        Column
                      </Link>
                      <p>Universal Columns Available.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/building-products">
                    <h2 className="title">BUILDING PRODUCTS</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Cutting Discs
                      </Link>
                      <p>Quality disc metal cut offs in a range of sizes.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Masks & Gloves
                      </Link>
                      <p>Orrcon Steel have a range of welding masks. We also provide.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Deformed Bar
                      </Link>
                      <p>Deformed bar is used where extra reinforcing is required to fabric.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Rods & Wires
                      </Link>
                      <p>Welding rods and wire are used in the welding process as a filler.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Trade Tools
                      </Link>
                      <p>Our trade tools range will assist you with your building or roofing.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/building-products" className="mb-2">
                        Mesh
                      </Link>
                      <p>Reinforcing mesh to AS/NZS 4671 standard.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/roofing-products" className="mb-2">
                    <h2 className="mb-4">ROOFING PRODUCTS</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Roofing & Wall Profiles
                      </Link>
                      <p>Orrcon Steel panels and profiles offer an attractive.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Rainwater Products
                      </Link>
                      <p>Orrcon Steel supplies a range of rainwater product needs.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Purlins and Girts
                      </Link>
                      <p>Purlins & girts are made from high tensile steel.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/roofing-products" className="mb-2">
                        Battens
                      </Link>
                      <p>We supply a range of steel roofing battens.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/fencing-products" className="mb-2">
                    <h2 className="title">FENCING PRODUCTS</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/fence-panels" className="mb-2">
                        Fence Panels
                      </Link>
                      <p>Manufactured from Australian, long life pre-painted COLORBOND® and extensively tested in harsh Australian weather conditions.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/fencing-extras" className="mb-2">
                        Fencing Extras
                      </Link>
                      <p>Whatever fence project needs attention, all your fencing accessories can be found at Orrcon Steel.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/fencing-joints" className="mb-2">
                        Fencing Joints
                      </Link>
                      <p>If you're looking to build a galvanised tube fence, you're going to need galvanised fencing joints on all posts.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/gate-packs" className="mb-2">
                        Gate Packs
                      </Link>
                      <p>Orrcon Steel is a leading supplier of fence and gate fittings and has a comprehensive range of gate packs to suit most fence designs.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/handrail-systems" className="mb-2">
                        Handrail Systems
                      </Link>
                      <p>Orrcon Steel offers a comprehensive range of profiled handrail system products, these fabricated handrail components include stanchions, bends.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/mesh-wires" className="mb-2">
                        Mesh & Wires
                      </Link>
                      <p>Orrcon Steel's extensive wide range of fencing wire & mesh are made to meet Australian Standards, with quality testing to ensure trouble free.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/post-caps" className="mb-2">
                        Post Caps
                      </Link>
                      <p>Orrcon Steel supplies a range of post caps and finials in many sizes and styles.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/rural-fencing-gates" className="mb-2">
                        Rural Fencing & Gates
                      </Link>
                      <p>Orrcon Steel stocks a range of fence posts and gates for rural farming applications.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/fencing-products/tools" className="mb-2">
                        Tools
                      </Link>
                      <p>Orrcon Steel supplies a range of quality building and fencing tools to make building and maintaining your fence as easy as possible.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/orrfire-firepipe" className="mb-2">
                    <h2 className="title">FIREPIPE</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/orrfire-firepipe/activfire-scheme" className="mb-2">
                        Activfire Scheme
                      </Link>
                      <p>ACTIVFIRE® Scheme is your assurance that our products have met the stringent requirements of CSIRO's accreditation process.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/orrfire-firepipe/firepipe_coatings" className="mb-2">
                        Firepipe Coatings
                      </Link>
                      <p>SMARTCOTE® Firepipe Coating (painted in Venetian Red).</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/orrfire-firepipe/pressure-drops-flow-rates" className="mb-2">
                        Pressure Drops & Flow Rates
                      </Link>
                      <p>Orrcon Steel have created full specification tables to assist pipe fabrication.</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center content-row">
          <Col xs={10}>
            <Row className="d-flex align-items-center">
              <Col sm={3}>
                <div className="title-container p-3">
                  <Link to="/product-info/coatings-finishes-available" className="mb-2">
                    <h2 className="mb-4">COATING & FINISHES</h2>
                  </Link>
                </div>
              </Col>
              <Col sm={9}>
                <Row>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/allgal" className="mb-2">
                        ALLGAL® Coating
                      </Link>
                      <p>Whether it's for a shade structure, cattle yard, bus shelter, floor framing or fence post.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/hot-dipped-galvanised-coatings-finishes" className="mb-2">
                        Hot Dipped Galvanised Coatings & Finishes
                      </Link>
                      <p>Hot Dip Galvanised finished products are perfect for jobs that require longer lasting protection against all weather and all conditions.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/liquid-allgal-coating" className="mb-2">
                        Liquid ALLGAL Coating
                      </Link>
                      <p>Liquid ALLGAL® is a fast drying, high solids and high performance zinc-rich epoxy coating.</p>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/smartcote" className="mb-2">
                        SMARTCOTE
                      </Link>
                      <p>SMARTCOTE® offers a number of advantages over oil based steel coatings and is used in a broad range of applications including welding, galvanising.</p>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="content info">
                      <Link to="/product-info/coatings-finishes-available/standard-finishes" className="mb-2">
                        Standard Finishes
                      </Link>
                      <p>Orrcon offers a range of other Standard Finishes like Mill Finish, NOPC, MSGB and more...</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default ProductInfoPage

export const Head = () => <title>Product Information</title>
